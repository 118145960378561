import { Directive, ElementRef } from "@angular/core";

@Directive({
    selector: '[ng-select]',
    standalone: true
})
export class NgSelectDirective {
    constructor(
        private el: ElementRef<HTMLElement>,
    ) {}

    ngOnInit() {
        const btn = this.el.nativeElement.getElementsByTagName("button")[0]
        if(btn) {
            btn.addEventListener('click', ()=> {
                this.el.nativeElement.classList.toggle('on')
            })

            document.addEventListener('click',(e:any)=>{
                const select = document.querySelector('.ng-select.on')
                if(select && !select.contains(e.target)){
                    select.classList.remove('on')
                }
            })
        }

        // $('body').on('click' , '.custom_select2 ul li', function() {
        //     $(this).parents('.custom_select2').removeClass('on')
        //     $(this).parents('.custom_select2').find('button .name').html($(this).find('.name').html())
        //     $(this).addClass('on').siblings().removeClass('on')
        // })
    }
}