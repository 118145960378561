import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { PaginationService } from '@app/shared/lib/pagination.service';


@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    CommonModule,
    MomentPipe
  ],
  templateUrl: './pagination.component.html',
  styles: ``
})
export class PaginationComponent {
    @Input() total: number = 0;
    @Input() page: number = 1;
    @Input() limit: number = 20;
    @Input() pageBlock: number = 10;
    
    @Input() btnFirst: boolean = false; // 맨 처음으로
    @Input() btnLast: boolean = false; // 맨 뒤로
    
    
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    pagination: any = {};
    constructor(
        private paginationService: PaginationService,
    ) { }

    ngOnInit(): void {
        this.pagination = this.paginationService.init(this.total, this.page, this.limit, this.pageBlock, this.btnFirst, this.btnLast);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.pagination = this.paginationService.init(this.total, this.page, this.limit, this.pageBlock, this.btnFirst, this.btnLast);
    }

    Go(page: number, $event: any = null) {
        if($event) $event.preventDefault();
        this.pageChange.emit(page);
    }
}
