import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: true,
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], field : string, value:any, contains:boolean=true): any[] {
        if (!items) return [];
        if (!value || value.length === 0) return items;

        if(contains) {
            return items.filter(it => it[field].toString().toUpperCase().indexOf(value.toString().toUpperCase()) !== -1);
        } else{
            return items.filter(it => it[field] === value);
        }
    }
}