import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    constructor() {}
    
    /**
     * @param total
     * @param page = 1
     * @param limit = 20
     * @param pageBlock = 10
     */
    init(total: number, page: number = 1, limit:number = 20, pageBlock: number = 10, btn_first: boolean = false, btn_last: boolean = false) {
        let pageStart: number, lastPage: number;

        lastPage = Math.ceil(total / limit);
        if(lastPage < page) lastPage = page;

        if(page == 1) pageStart = 1;
        else  pageStart = Math.floor((page - 1) / pageBlock) * pageBlock + 1;


        // let prevPage:number  = pageStart - pageBlock; // 페이지 블럭의 처음으로 (1~10 => 1)
        let prevPage:number  = pageStart - 1;  // 페이지 블럭의 마지막으로 (1~10 => 10)
        let nextPage:number = pageStart + pageBlock;

        let pages = [];
        for(let i = pageStart; i < pageStart + pageBlock; i++) {
            pages.push(i);
            if(i >= lastPage) break;
        }

        return {
            total: total,
            page: page,
            pageBlock: pageBlock,
            pageStart: pageStart,
            lastPage: lastPage,
            pages: pages,

            /**
             * buttons
             */
            first: (!btn_first) ? false : (page != 1 && prevPage >=  1) ? 1 : false,
            prev: (prevPage <  1) ? false : prevPage,
            next: (nextPage > lastPage) ? false : nextPage,
            last: (!btn_last) ? false : (page != lastPage && nextPage <= lastPage) ? lastPage : false,
        };
    }
}